.gridGap156{grid-gap: 20px;margin-bottom: 60px; align-items: center; justify-content: space-between;}
.integrationList{max-width: 463px;width: 440px;flex-shrink: 0;display: flex;flex-direction: column;grid-gap: 18px;}
.integrationListSubBox{display: flex;justify-content: center;grid-gap:30px ;position: relative;width: 100%;}
.integrationList{justify-content: center;align-items: center;flex-wrap: wrap; grid-template-columns: repeat(3, 1fr);}
.listItem{width: 126px;height: 126px;border: solid 14px var(--primary-color);display: flex;justify-content: center;align-items: center;border-radius: 50%;box-shadow: 0 0  16px rgba(0, 0, 0, 0.04);}
.listIcon{position: absolute;animation: shake 10s infinite; animation-iteration-count: infinite;  -webkit-animation-fill-mode: both; animation-fill-mode: both;}
.listIcon1{left: 149px;top: -14px;animation-delay: 0.5s;}
.listIcon2{left: 44px;top: 20px;animation-delay: 1s;}
.listIcon3{left: 222px;top: -1px; animation-delay: 0.2s;}
.listIcon4{right: 0;top: -14px;animation-delay: 1s;}
.listIcon5{left: 145px;top: 7px;animation-delay: 0.6s; }
.listIcon6{right: 100px;top: -15px;animation-delay: 0.4s;}
.listIcon7{right: 140px;bottom: 0;animation-delay: 0.7s;}

.integrationText h3 {
   font-size: 42px;
   font-family: var(--poppins-bold);
   line-height: 60px;
   color: var(--secondry-color);
}
.integrationText ul {
   padding-left: 20px;
   list-style: none;
   position: relative;
   margin-top: 30px;
 }
 
 .integrationText ul li {
   line-height: 26px;
   color: #343434;
   font-size: 16px;
   font-family: 'poppinsmedium';
   position: relative;
   margin-top: 4px;
 }
 
 .integrationText ul li::before {
   content: '';
   background-color: #343434;
   width: 5px;
   height: 5px;
   border-radius: 50%;
   position: absolute;
   top: calc(50% - 3px);
   left: -20px;
 }


@-webkit-keyframes shake {
    0%, 100% {-webkit-transform: translateX(0);} 
    10%, 30%, 50%, 70%, 90% {-webkit-transform: translateX(-6px);} 
    20%, 40%, 60%, 80% {-webkit-transform: translateX(6px);} 
 }
 
 @keyframes shake { 
    0%, 100% {transform: translateX(0);} 
    10%, 30%, 50%, 70%, 90% {transform: translateX(-6px);} 
    20%, 40%, 60%, 80% {transform: translateX(6px);} 
 }
/* .listOne{grid-column: col / span 2;grid-row: row;}
.listTwo{grid-column: col 3 / span 2;grid-row: row;}
.listThree{grid-column: col;grid-row: row 2;}
.listFour{grid-column: col 2 / span 3;grid-row: row 2;}
.listFive{grid-column: col / span 4;grid-row: row 3;} */

@media screen and (max-width:991.98px) {
   .integrationText h3 br {
      display: none;
   }
}
@media screen and (max-width:767.98px) {
   .gridGap156 {
      flex-flow: column nowrap;
      gap: 50px;
   }
   .integrationText h3 {
      font-size: 22px;
      line-height: 34px;
   }
   .integrationList {
      width: 100%;
   }
   .integrationListSubBox {
      gap: 12px;
   }
   .listItem {
      width: 100px;
      height: 100px;
      border-width: 8px;
      overflow: hidden;
   }
}