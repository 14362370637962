.videoCommerce{background: url('../../../../public/assets/images/aibackground.jpg') no-repeat center / cover;}
.aiSlideBox{display: flex;justify-content: space-between;grid-gap: 30px;}
.aiSlideItem figure{width: 390px;height: 390px;}
.aiSub{color: #D5D5D5;}
.aiBG{background: var(--yellowBtn);color: var(--191919);display: inline-flex;padding: 0 8px;}
.aiImageSection{background: #fff;border-radius: 8px;padding: 26px;display: flex;justify-content: center;align-items: center;}
.aiImageSection.transparent{background: transparent;padding: 0 26px;align-items: flex-start;max-height: 451px;overflow: hidden;}
.aiImageSection.transparent img{height: 100%;}
.aiList{display: flex;flex-direction: column;grid-gap: 30px;max-width: 50%;}
.aiList li{position: relative;padding-left: 33px;display: flex;flex-direction: column;grid-gap: 15px;overflow: hidden;cursor: pointer;}

/* .aiList li p{display: none;} */
.aiList li::after{content: '';width: 13px;height: 13px;background: var(--aiYellow);display: inline-flex;flex-shrink: 0;border-radius: 50%;position: absolute;left: 0;top: 5px;}

.aiList li.active{background: var(--aiYellow);padding: 20px 30px 40px;border-radius: 8px;}
.aiList li.active h3{color: #0E0D12;font-size: 20px ;}
.aiList li.active p{display: block;color: #343434;line-height: 22px;}
.loader{background: #BEB018;display: flex;height: 7px;position: absolute;bottom: 0;width: 0;left: 0;display: none;}
.aiList li.active .loader{display: block;animation: mymove 7.5s 1;}
.serviceText{display: flex;flex: 1 1 auto;align-items: stretch;margin: 0;padding: 0;max-width:600px;overflow: hidden;height: 0;}
.serviceText1{display: flex;flex: 1 1 auto;align-items: stretch;margin: 0;padding: 0;max-width:600px;overflow: hidden;}
.aiList li.active .serviceText{height: auto;}
.activeNumber{display: inline-flex;width: 30px;height: 30px;font-size: 20px;justify-content: center;align-items: center;padding: 0 18px;background: #E6E6E6;border-radius: 6px;margin-right: 5px;}
.aiList1{grid-gap: 25px;}
.aiList1 li{background: #fff; padding: 20px 30px 20px;border-radius: 8px;}
.aiList1 li.active{background: var(--aiYellow);padding: 20px 30px 20px;border-radius: 8px;}
.aiList1 li.active .activeNumber{background: #fff;}
.aiList1 li h3{color: #0E0D12;}
.aiList1 li p{display: block;color: #343434;line-height: 22px;}
.aiList1 li::after{display: none;}
.imageTraction.active{transition: opacity 0.3s ease-in-out;opacity: 1;width: auto;height: 100%;}
.imageTraction{opacity: 0;width: 0;}
.aiList1 li.mobileLi{grid-gap: 12px;padding: 20px;}
.aiList1 li.mobileLi p{line-height: 18px;}
.headingBorder{border:solid 1px #fff;padding: 14px 22px;
  border-radius: 12px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;}
@keyframes mymove {
    0%   {width: 0px;}
    100% { width: 100%;}
  }

  @media screen and (max-width:1200px) {
    .aiSlideBox {
      align-items: center;
      justify-content: center;
    }
  }
@media screen and (max-width:768px) {
    .aiList{max-width: 100%;}
    .aiSlideBox{flex-direction: column;}
    .videoCommerce{flex-direction: column;}
}