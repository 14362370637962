.ab-hero {
  background-color: var(--primary-color);
}
.hero-row {
  display: flex;
}
.hero-content {
  position: relative;
}
.hero-content h1 {
  color: var(--secondry-color);
  font-family: var(--poppins-bold);
}
.hero-content > p {
  color: var(--secondry-color);
  margin-top: 15px;
}
.hero-form {
  margin-top: 20px;
}
.hero-form .inputControl {
  margin-bottom: 10px;
}
.hero-form .form-lagend {
  color: var(--secondry-color);
  text-align: center;
  font-size: 12px;
  line-height: 16px;
}
.hero-thumb {
  position: relative;
}
.hero-thumb .thumb-box {
  background: var(--primary-color);
  border: solid 1px #ffffff;
  border-radius: 20px;
  padding: 20px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 46px rgba(255, 255, 255, 0.75);
  overflow: hidden;
}
.hero-thumb .thumb-box img {
  height: auto;
}
@media screen and (max-width: 991.98px) {
  .ab-hero {
    padding: 40px 0;
  }
  .hero-row {
    flex-flow: column-reverse nowrap;
    justify-content: space-between;
    gap: 30px;
  }
  .hero-content h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .hero-content h1 br {
    display: none;
  }
  .hero-content h1 span {
    margin-bottom: 10px;
  }
  .hero-content > p {
    font-size: 16px;
    line-height: 22px;
  }
  .hero-thumb .thumb-box img {
    width: 320px;
  }
  
  .hero-form .form-lagend {
    max-width: 520px;
  }
}

@media screen and (min-width: 992px) {
  .ab-hero {
    padding: 60px 0;
  }
  .hero-row {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .hero-content h1 {
    font-size: 38px;
    line-height: 48px;
  }
  .hero-content > p {
    font-size: 18px;
    line-height: 26px;
  }
  .hero-thumb .thumb-box img {
    width: 420px;
  }
}
@media screen and (min-width: 1200px) {
  .ab-hero {
    padding: 60px 0;
  }
  .hero-content h1 {
    font-size: 45px;
    line-height: 55px;
  }
  .hero-content > p {
    font-size: 20px;
    line-height: 30px;
  }
  .hero-thumb .thumb-box img {
    width: 520px;
  }
  .hero-form .form-lagend {
    max-width: 520px;
  }
}
