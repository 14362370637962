.galleryModal {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 40;
}
.galleryModal .modalBody {
    width: 600px;
    height: 600px;
    position: relative;
}
.galleryModal .modalBody img {
    width: 100%;
}
.galleryModal .modalBody .closeModal {
    position: absolute;
    left: 100%;
    top: 0;
    width: 30px;
    height: 30px;
    border: 1px solid var(--secondry-color);
    background-color: var(--secondry-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.pageOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 30;

}

@media screen and (max-width:575.98px) {
    .galleryModal .modalBody {
        width: 80%;
        height: auto;
    }
}
@media screen and (min-width:576px) {
    .galleryModal .modalBody {
        width: 380px;
        height: auto;
    }
}
@media screen and (min-width:768px) {
    .galleryModal .modalBody {
        width: 420px;
        height: auto;
    }
}
@media screen and (min-width:992px) {
    .galleryModal .modalBody {
        width: 460px;
    }
}