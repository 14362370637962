.gridgap{
    grid-gap: 44px;
    justify-content: space-between;
    width: 100%;
    background: #F2F3F7;
    padding: 28px 20px;
    border-radius: 20px;
    display: flex;
}
.ReImage {
    padding:0px 0  80px;
}
.ReImageThumb {
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
}
.ReImageThumb figure {
    text-align: center;
}
@media screen and (max-width:991.98px) {
    .gridgap {
        flex-flow: column nowrap;
    }
    .ReImageContent h4 {
        font-size: 32px;
    }
    .ReImageContent p {
        font-size: 20px;
    }
    .ReImageContent p br {
        display: none;
    }
    .listEL li {
        font-size: 13px;
    }
}
@media screen and (max-width:767.98px) {
    .gridgap {
        flex-flow: column nowrap;
    }
    .ReImageContent h4 {
        font-size: 30px;
    }
    .ReImageContent p {
        font-size: 20px;
    }
    .listEL {
        flex-flow: row wrap;
    }
}
@media screen and (max-width:575.98px) {
    .ReImageContent h4 {
        font-size: 24px;
    }
    .ReImageContent p {
        font-size: 16px;
    }
}
@media screen and (min-width:992px) {
    .ReImageContent h4 {
        font-size: 38px;
    }
    .ReImageContent p {
        font-size: 24px;
    }
    .ReImageContent p br {
        display: none;
    }
    .listEL li {
        font-size: 13px;
    }
    
    .ReImageThumb {
        width: 320px;
    }
}
@media screen and (min-width:1200px) {
    .listEL li {
        font-size: 14px;
    }
    .ReImageContent h4 {
        font-size: 48px;
    }
    .ReImageContent p br {
        display: inline-block;
    }
    .ReImageThumb {
        width: 480px;
    }
}

@media screen and (min-width:1400px) {
    .listEL li {
        font-size: 16px;
    }
}