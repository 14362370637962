.hm-commerce {
    background: url('/public/assets/images/aibackground.jpg') no-repeat center / cover;
    padding: 80px 0 120px 0;
}
.secHeading {
    text-align: center;
    margin-bottom: 50px;
}
.headingTitle {
    font-size: 50px;
    color: var(--white-color);
    line-height: 50px;
    font-family: 'poppinsbold';
}
.headingTitle mark {
    background-color: var(--primary-color);
    padding: 0 10px;
}
.headingdisc {
    line-height: 26px;
    font-size: 18px;
    color: #D5D5D5;
    margin-top: 30px;
    margin-bottom: 0;
}
.commerceWrapper {
    width: 100%;
}
.commerceWrapper .commerceBox {
    position: relative;
}

.commerceWrapper .commerceCircle {
    position: relative;
}
.commerceWrapper .commerceCircle::before {
    content: '';
    position: absolute;
    width: 360px;
    height: 360px;
    margin: 0.8px 2.9px 0.2px 0.8px;
    padding: 2px 3.4px 1.7px 2.8px;
    opacity: 0.75;
    border: solid 4px rgba(84, 184, 89, 0.1);
    background-image: linear-gradient(to bottom, rgba(245, 214, 95, 0.43), rgba(245, 214, 95, 0.05) 5%, rgba(245, 214, 95, 0.05) 95%, rgba(245, 214, 95, 0.39)); 
    border-radius: 50%;
    transform: rotate(45deg);
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}
.commerceCircle .commerceThumb {
    position: relative;
    width: 300px;
    margin: 0 auto;
}
.commerceCircle .commerceThumb img {
    width: 100%;
}
.featureList {
    padding: 20px 20px 20px 60px;
    background: rgb(84,54,28);
    background: linear-gradient(90deg, rgba(84,54,28,0.66) 0%, rgba(84,54,28,0.66) 35%, rgba(74,39,60,0.66) 100%);
    border-radius: 20px;
    transition: all 0.3s ease-in;
}
.featureList .featureTitle {
    position: relative;
}
.featureList .featureTitle .featureName {
    position: relative;
    cursor: pointer;
    font-weight: 400;
}
.featureList .featureTitle .featureName em {
    background-color: var(--primary-color);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    left: -40px;
    transition: all 0.3s ease-in;
    transform: rotateZ(0deg);
}
.featureList .featureTitle .featureName em:before,
.featureList .featureTitle .featureName em:after {
  content: "";
  position:absolute;
  background-color: var(--secondry-color);
  border-radius: 5px;
}
.featureList .featureTitle .featureName em:before {
  left:50%;
  top:5px;
  bottom:5px;
  width:1.5px;
  transform:translateX(-50%);
}
.featureList .featureTitle .featureName em:after {
  top:50%;
  left:5px;
  right:5px;
  height:1.5px;
  transform:translateY(-50%);
}
.featureList .featureTitle .featureName span {
    color: var(--white-color);
    font-size: 20px;
    line-height: 26px;
    font-family: var(--poppins-regular);
}
.featureList .featurePara {
    height: 0px;
    overflow: hidden;
    transition: all 0.3s linear;
}
.featureList .featurePara p {
    color: var(--white-color);
    line-height: 22px;
    margin-top: 10px;
}

.commerceFeature .leftCircle {
    left: 0px;
}

.featureList.focused {
    z-index: 2;
    transition: all 0.3s ease-in;
}
.featureList.focused .featurePara {
    max-height: 100%;
    min-height: 80px;
    transition: all 0.3s ease-in;
}
.featureList.focused .featureTitle .featureName em {
    transform: rotateZ(45deg);
    transition: all 0.3s ease-in;
}
.featureList.blurred {
    z-index: 1;
    opacity: 0.1;
    transition: all 0.3s ease-in;
}
@media screen and (max-width:767.98px) {
    .hm-commerce {
        padding: 50px 0;
    }
    .headingTitle {
        font-size: 30px;
        line-height: 40px;
    }
    .commerceBox {
        display: flex;
        flex-flow: row-reverse nowrap;
        justify-content: center;
    }
    .commerceFeature .leftCircle,
    .commerceFeature .rightCircle {
        gap: 20px;
        display: flex;
        flex-flow: column nowrap;
    }
    .commerceFeature .rightCircle {
        margin-top: 20px;
    }
    .commerceWrapper .commerceCircle {
        display: none;
    }
}
@media screen and (min-width:768px) {
    .commerceBox {
        display: flex;
        flex-flow: row-reverse nowrap;
        justify-content: center;
    }
    .commerceWrapper .commerceCircle {
        height: 430px;
        width: 400px;
    }
    .commerceWrapper .commerceFeature {
        width: calc(100% - 400px);
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;
    }
    .commerceFeature .leftCircle,
    .commerceFeature .rightCircle {
        width: 345px;
        gap: 20px;
    }
    .commerceFeature .rightCircle {
        margin-top: 20px;
    }
    
    .commerceFeature .leftCircle,
    .commerceFeature .rightCircle {
        top: 0px;
        display: flex;
        flex-flow: column nowrap;
    }
    .commerceFeature .leftCircle {
        left: 0px;
    }
    .commerceFeature .rightCircle {
        right: 0px;
    }
    
    .commerceFeature .leftCircle .featureList .featureTitle .featureName {
        text-align: left;
    }
    .commerceFeature .leftCircle .featureList .featureTitle .featureName em {
        left: -40px;
    }
}
@media screen and (min-width:992px) {

    .commerceWrapper .commerceCircle {
        position: relative;
    }
    .commerceWrapper .commerceCircle {
        height: 430px;
        width: 400px;
    }
    .commerceWrapper .commerceFeature {
        width: calc(82% - 400px);
    }
    


}
@media screen and (min-width:1200px) {

    .commerceFeature .leftCircle .featureList:nth-child(1),
    .commerceFeature .rightCircle .featureList:nth-child(1) {
        top: 95px;
    }
    .commerceFeature .leftCircle .featureList:nth-child(2),
    .commerceFeature .rightCircle .featureList:nth-child(2) {
        top: 200px;
    }
    .commerceFeature .leftCircle .featureList:nth-child(3),
    .commerceFeature .rightCircle .featureList:nth-child(3) {
        top: 320px;
    }
    .commerceFeature .leftCircle .featureList:nth-child(2) {
        left: -25px;
    }
    .commerceFeature .rightCircle .featureList:nth-child(2) {
        right: -25px;
    }    
    .commerceWrapper .commerceFeature {
        position: absolute;
        width: 88%;
        left: 6.5%;
    }
    .commerceFeature .leftCircle,
    .commerceFeature .rightCircle {
        width: 345px;
        top: 0px;
        position: absolute;
        display: flex;
        gap: 40px;
        flex-flow: column nowrap;
    }
    .commerceFeature .leftCircle {
        left: 0px;
    }
    .commerceFeature .rightCircle {
        right: 0px;
    }
    .featureList {
        position: absolute;
        width: 330px;
    }
    .commerceWrapper .commerceCircle {
        height: 430px;
        width: 400px;
    }
    .commerceFeature .leftCircle .featureList {
        padding: 20px 60px 20px 20px;
    }
    .commerceFeature .leftCircle .featureList .featureTitle .featureName {
        text-align: right;
    }
    .commerceFeature .leftCircle .featureList .featureTitle .featureName em {
        right: -40px;
        left: auto;
    }
    .commerceFeature .leftCircle,
    .commerceFeature .rightCircle {
        width: 345px;
        gap: 40px;
    }
}