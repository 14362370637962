/*number of recort*/
.numberSay{display: flex;justify-content: space-between;grid-gap: 21px;flex-wrap: wrap;}
.numberBox{flex: 1;padding: 25px 30px;background: rgb(81,77,33);background: linear-gradient(180deg, rgba(81,77,33,1) 0%, rgba(48,46,27,1) 20%, rgba(27,27,24,1) 40%);border-radius: 30px;max-width: 264px;}
.numberBox p{position: relative;width: fit-content;line-height: 60px;display: flex; color: var(--primary-color);}
.numberBox .count{position: relative;}
.numberBox figure{margin-bottom: 40px;display: block;}
.curicleBlink:after,.curicleBlink:before{
    position: absolute;
    left: 0;
/*    margin: 0 auto;*/
    height: 50px;
    width: 50px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    -webkit-box-shadow: 0 0 0 0 rgba(228, 214, 70, 0.3);
    -moz-box-shadow: 0 0 0 0 rgba(228, 214, 70, 0.3);
    -ms-box-shadow: 0 0 0 0 rgba(228, 214, 70, 0.3);
    -o-box-shadow: 0 0 0 0 rgba(228, 214, 70, 0.3);
    box-shadow: 0 0 0 0 rgba(228, 214, 70, 0.3);
    -webkit-animation: rippleSmall 3s infinite;
    -moz-animation: rippleSmall 3s infinite;
    -ms-animation: rippleSmall 3s infinite;
    -o-animation: rippleSmall 3s infinite;
    animation: rippleSmall 3s infinite;
    -webkit-transition: all ease .4s;
    -ms-transition: all ease .4s;
    transition: all ease .4s;
}
.curicleBlink{right: 30px;top: 14px;background: rgba(217, 202, 56, 0.1);position: absolute;}
.curicleBlink:before {
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    -ms-animation-delay: .9s;
    -o-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
    top: 0;
}
.curicleBlink:after {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -ms-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s;
    content: "";
    position: absolute;
    top: 0;
}
@media screen and (max-width:768px) {
    .numberBox{width: 45%;padding: 15px 20px;}
    .numberBox figure{margin-bottom: 10px;height: 30px;}
}