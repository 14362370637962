.faqBox{padding: 100px 0}
.faqContainer{display: flex;justify-content: space-between;grid-gap: 175px}
.questionBox{border-top: 1px solid #808080;border-bottom:1px solid  #808080;padding: 15px 0;cursor: pointer;}
.questionBox .anwser{max-height: 0px;transition:  0.2s; overflow: hidden;}
.questionBox.active .anwser{max-height: 400px;margin-top: 20px;margin-bottom: 20px;transition: 0.2s;}
.questionBox > p{position: relative;padding-right: 20px;}
.questionBox > p .icon{position: absolute;width: 20px;height: 20px;display: flex;justify-content: center;align-items: center;right: 0;top: 0}
.questionBox > p .icon::after{width: 2px;height: 100%;background: #000;content: '';position: absolute;}
.questionBox > p .icon::before{width: 100%;height: 2px;background: #000;content: '';position: absolute;}
.questionBox.active p .icon::after{display: none;}
.anwser p{font-size: 16px;line-height: 26px;margin-bottom: 10px;}
@media screen and (max-width:768px) {
    .faqBox{padding: 30px 0;}
    .faqContainer{flex-direction: column;}
    .faqHeading{display: flex;grid-gap: 20px;}
    .faqHeading img{height: 59px;}
    .faqContainer{grid-gap: 30px;}
    .questionBox.active .anwser{margin-top: 15px;margin-bottom: 0px;}
}