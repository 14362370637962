.testimonialSec {
    background-color: var(--primary-color);
    padding: 80px 0;    
    background-image: url("/public/assets/images/doodles/feedback-bg-doodle.png");
    background-size: 350px 350px;
}
.secHeading {
    text-align: center;
    margin-bottom: 50px;
}
.headingTitle {
    font-size: 50px;
    color: #0e0d12;
    line-height: 50px;
    font-family: 'poppinsbold';
}
.TestimonialBox {
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
}
.TestimonialCard {
    width: calc(calc(100% - 40px) / 3);
}
.TestimonialCardBox {
    overflow: hidden;
    background-color: var(--white-color);
    flex:none;
    border-radius: 15px;
}

.TestimonialMsg p {
    color: #0e0d12;
    position: relative;
}
.TestimonialMsg p span {
    background-color: var(--primary-color);
    color: var(--secondry-color);
}
.TestimonialMsg p::before,
.TestimonialMsg p::after {
    color: #0e0d12;
    font-weight: 600;
    font-family: Arial;
    position: absolute;
    opacity: 0.4;
}
.TestimonialMsg p::before {
    content: "“";
}
.TestimonialMsg p::after {
    content: "”";
}

.TestimonialVideo {
    width: 100%;
    cursor: pointer;
    height: 240px;
    position: relative;
    background-color: #f1f1f1;
    line-height: 0px;
}
.TestimonialVideo img {
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.TestimonialVideo img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    object-position: center;
}
.TestimonialProvider {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0px 22px 8px 66px;
    align-items: center;
}
.TestimonialsName h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #0e0d12;
}
.TestimonialsName p {
    font-size: 14px;
    font-weight: 400;    
    color: #0e0d12;
    margin-top: 2px;
}
.TestimonialsLogo  img{
    max-height: 20px;
}
.TestimonialVideo figure {
    position: relative;
    width: 100%;
    height: 100%;
}

.clientSliderDot {
    bottom: -30px;
    display: block;
}

.clientSliderDot button::before{
    font-size: 14px !important;
}
.TestimonialsCaptions {
    background-color: #fff;
    min-height: 360px;
}
.TestimonialMsg {
    padding: 50px 70px;
}
.TestimonialMsg p {
    font-size: 16px;
    color: #0e0d12;
    position: relative;
    line-height: 20px;
}
.TestimonialMsg p::before,
.TestimonialMsg p::after {
    color: #0e0d12;
    font-size: 100px;
    font-weight: 600;
    font-family: Arial;
    position: absolute;
    opacity: 0.4;
}
.TestimonialMsg p::before {
    content: "“";
    top: -6px;
    left: -60px;
}
.TestimonialMsg p::after {
    content: "”";
    right: -55px;
    bottom: -44px;
}
@media screen and (max-width:1999.98px) {
    .TestimonialBox {
        display: flex;
        flex-flow: row nowrap;
        overflow-x: scroll;
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    .TestimonialBox::-webkit-scrollbar {
        display: none;
      }
    .TestimonialCard {
        min-width: 380px;
        flex: none;
    }
}
