/* .AddOnsMain{margin-bottom: 90px;} */
.AddOnsBox {
    display: flex;
    margin-top: 50px;
    width: 100%;
    flex-flow: row wrap;
}
.AddOnsItem {
    padding: 25px;
    border-radius: 40px;
    position: relative;
    cursor: pointer;
    width: calc(calc(100% - 60px) / 3);
    flex: none;
}
.AddOnsTitle {
	display: flex;
	grid-gap: 15px;
	align-items: center;
	margin-bottom: 15px;
    padding-left: 40px;
}
.AddOnsTitle figure {
	width: 52px;
	height: 52px;
	border: solid 1px #F2F3F7;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
}
.AddOnsTitle figure img {
	display: block;
}
.tryNow {
    border-top: solid 1px #9f9c9c;
    display: flex;
    grid-gap: 10px;
    margin: 0 -25px;
    margin-top: 20px;
    padding: 15px;
    padding-bottom: 0px;
    display: flex;
}
.tryNow p {
    margin-left: auto;
    color: var(--secondry-color);
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding-right: 10px;
}

.thumbBox {
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    left: -4px;
    top: 10px;
}
.thumbBox figure {
    position: absolute;
    width: 52px;
    height: 52px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}
.thumbBox figure::after,
.thumbBox figure::before {
    content: '';
    position: absolute;
    border-radius: 50%;
}
.thumbBox figure::before {
    background-image: linear-gradient(107deg, #f4f1e7 9%, #0e0d12 93%);
    z-index: 1;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
}
.thumbBox figure::after {
    background-color: #fff;
    z-index: 2;
    left: 2px;
    top: 2px;
    width: 46px;
    height: 46px;
}
.thumbBox figure img {
    z-index: 3;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 26px;
}
.AddOnsContent {
    padding-left: 60px;
}
.AddOnsContent h4 {
    color: var(--secondry-color);
    font-size: 20px;
    line-height: 24px;
    font-family: var(--poppins-medium);
}
.AddOnsContent p {
    font-size: 14px;
    color: #666;
    line-height: 22px;
    margin-top: 10px;
}

.AddOnsItem:hover .tryNow {
    border-top: solid 1px #f2c000;
}
.AddOnsItem:hover .thumbBox figure::before {
    background-image: linear-gradient(107deg, #f4f1e7 9%, #f5d65f 93%);
}
.secHeading {
    margin-bottom: 25px;
}
@media screen and (max-width:767.98px) {
	.AddOnsMain {
		margin-bottom: 0px;
	}
	.AddOnsBox {
		grid-template-columns: repeat(1,auto);
	}
	.secHeading {
		font-size: 36px;
		line-height: 50px;
	}
    .AddOnsItem {
        background: #e8e8e8;
        width: 100%;
    }
    
    .thumbBox {
        left: 15px;
    }
}
@media screen and (max-width:575.98px) {
    .AddOnsBox {
        gap: 15px;
    }
    .AddOnsItem {
        width: 100%;
    }
}
@media screen and (min-width:576px) {
    .AddOnsBox {
        gap: 15px;
    }
}
@media screen and (min-width:768px) {
    .AddOnsItem {
        background-image: url("/public/assets/images/add-card-bg-2.png");
        background-repeat: no-repeat;
        background-position: top left;
        background-color: rgb(0 0 0 / 5%);
    }
    .AddOnsItem:hover {
        background-color: var(--primary-color);
    }
    
    .AddOnsContent {
        min-height: 125px;
    }
    .AddOnsItem {
        width: calc(calc(100% - 15px) / 2);
    }
}
@media screen and (min-width:992px) {

    .AddOnsItem {
        width: calc(calc(100% - 30px) / 3);
    }
}
@media screen and (min-width:1200px) {
    .AddOnsBox {
        gap: 30px;
    }
    .AddOnsItem {
        width: calc(calc(100% - 60px) / 3);
    }
}