.modal {position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 1050;display: none;overflow: hidden;outline: 0;background: rgba(0, 0, 0, 0.7);}
.modal.active{display: block;}
.fade {opacity: 0;transition: opacity .15s linear;}
.fade.show {opacity: 1;}
.modalOpen .modal {overflow-x: hidden;overflow-y: auto;}
.modalDialog {position: relative;width: auto;margin: 0.5rem;pointer-events: none;max-width: 240px;margin: auto;}
.modal.fade .modalDialog {transition: -webkit-transform .3s ease-out;transition: transform .3s ease-out;transition: transform .3s ease-out,-webkit-transform .3s ease-out;-webkit-transform: translate(0,-25%);transform: translate(0,-25%);}
.modal.show .modalDialog {transform: translate(0,10%);}
.modalContent {position: relative;display: -ms-flexbox;-webkit-box-orient: vertical;display: -webkit-box;-webkit-box-direction: normal;display: flex;-ms-flex-direction: column;flex-direction: column;width: 100%;pointer-events: auto;background-clip: padding-box;border: 1px solid rgba(0,0,0,.2);border-radius: 0.3rem;outline: 0;}
.modalHeader {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;padding: 1rem;border-top-left-radius: 0.3rem;border-top-right-radius: 0.3rem;}
.modalTitle {margin-bottom: 0;line-height: 1.5;}
.modalBody video{width: 100%;max-width: 240px;}
.btnClose {padding: 0;background-color: transparent;border: 0;-webkit-appearance: none;border: none;position: absolute;right: -20px;top: -15px;color: #fff;font-size: 20px;}
.btnClose:not(:disabled):not(.disabled) {cursor: pointer;}
.modal-body {position: relative;-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;padding: 1rem;}
@media (min-width: 576px){
    .modal-dialog {max-width: 500px;margin: 1.75rem auto;}
}