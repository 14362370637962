.loaderWrapper {
    min-height: calc(100vh - 110px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loaderBox {
    color: red;
}

@-webkit-keyframes tint {
    50% {
      background-color: transparent;
      transform: scale(0.8);
    }
  }
  @keyframes tint {
    50% {
      background-color: transparent;
      transform: scale(0.8);
    }
  }
  *,
  :before,
  :after {
    box-sizing: border-box;
  }
  

  .loaderBox {
    position: relative;
    color: #4f5156;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
  }
  .loaderBox__dot {
    width: 40px;
    height: 8px;
    background-color: transparent;
    display: inline-block;
    margin-right: 2px;
  }
  .loaderBox__dot:nth-child(1) {
    -webkit-animation: tint 1600ms infinite;
            animation: tint 1600ms infinite;
    background-color: #f58d50;
  }
  .loaderBox__dot:nth-child(2) {
    -webkit-animation: tint 1600ms infinite 200ms;
            animation: tint 1600ms infinite 200ms;
    background-color: #bcd64a;
  }
  .loaderBox__dot:nth-child(3) {
    -webkit-animation: tint 1600ms infinite 400ms;
            animation: tint 1600ms infinite 400ms;
    background-color: #2a728e;
  }
  .loaderBox__dot:nth-child(4) {
    -webkit-animation: tint 1600ms infinite 600ms;
            animation: tint 1600ms infinite 600ms;
    background-color: #fbcc33;
  }