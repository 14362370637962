.apiWidget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  grid-gap: 80px;
  border: solid 1px var(--F7E94D);
  box-shadow: 0px 0px 20px rgba(250, 233, 77, 0.5);
  border-radius: 12px;
}
.apiText {
  max-width: 741px;
}

@media screen and (max-width: 1199.98px) {
  .apiWidget {
    gap: 30px;
    padding: 30px;
  }
  .apiText {
    max-width: 600px;
  }
  .apiWidget .apiText h4 {
    line-height: 40px;
}
}
@media screen and (max-width: 991.98px) {
  .apiWidget {
    flex-flow: column nowrap;
    align-items: center;
  }
  .apiWidget .apiText {
    width: 100%;
  }
  .apiWidget .apiText h4 {
    text-align: center;
  }
  .apiWidget .apiText p {
    text-align: center;
  }
}
@media screen and (max-width: 767.98px) {
  .sectionTry {
    padding: 0;
  }
  .apiWidget {
    flex-flow: column nowrap;
    padding: 20px;
    gap: 20px;
  }
  .apiWidget .apiText h4 {
    font-size: 20px;
    line-height: 28px;
  }
}
