.topBanner {
  background-color: var(--primary-color);
  display: flex;
}

.topBanner2 {
  padding-top: 55px;
}

.bannertBox {
  display: flex;
}

.bannerContent {
  position: relative;
  flex: 1;
}

.bannerContent.jalc {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.sellFast {
  color: #C30000;
  font-size: 24px;
  font-weight: bold;
}

.topBannerHeading1 {
  font-weight: 900;
  color: #0E0D12;
  position: relative;
}

.topBannerHeading1 span {
  margin-bottom: 10px;
}

.topBannerHeading {
  font-weight: 900;
  color: #191919;
  position: relative;
}

.topBannerHeading::after {
  content: '';
  background: #d8c33c;
  width: 57px;
  height: 6px;
  display: block;
  position: absolute;
  bottom: -10px;
}

.bannerList {
  padding-left: 20px;
  list-style: none;
  position: relative;
}

.bannerList li {
  line-height: 26px;
  color: #343434;
  font-size: 16px;
  font-family: 'poppinsmedium';
  position: relative;
}

.bannerList li::before {
  content: '';
  background-color: #343434;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 3px);
  left: -20px;
}

.bannerImage {
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.bannerImage1 {
  margin-left: auto;
  max-width: 558px;
  height: 465px;
  background: #F7E94D;
  border: solid 1px #FFFFFF;
  border-radius: 20px;
  padding: 17px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 46px rgba(255, 255, 255, 0.75);
}

.bannerImage video {
  height: auto;
  object-fit: fill;
}

.sellFast {
  display: block;
  font-weight: 900;
  left: 0;
  height: 56px;
  overflow: hidden;
  animation: openclose 5s ease-in-out infinite;
  left: 0;
  top: 0;
}

.sellFast span {
  display: block;
}

.proudPart {
  padding: 13px 30px;
  border-radius: 40px;
  border: solid 1px #D1C337;
  width: fit-content;
  margin: 15px auto 25px;
  background: rgb(255, 245, 137);
  background: linear-gradient(218deg, rgba(255, 245, 137, 1) 0%, rgba(255, 251, 206, 1) 42%, rgba(255, 245, 134, 1) 62%, rgba(255, 244, 121, 1) 78%, rgba(221, 207, 68, 1) 100%);
}



.animateText {
  overflow: hidden;
  background: #FFFCDD;
  border-radius: 8px;
  display: flex;
}

.quickStart {
  border: solid 1px #343434;
  border-radius: 8px;
  background: #fff;
  padding: 5px 5px 5px 25px;
  max-width: 527px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.quickStart .btn {
  width: 155px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: auto;
  font-size: 14px;
}

.quickStart .btn>div {
  height: 40px;
}

.quickStart input {
  border: 0px;
  background: transparent;
  width: 100%;
  height: 51px;
  font-size: 16px;
}

.quickStartError {
  border-color: #C30000;
}

/* @keyframes openclose {
  0% {
    width: 0;
  }
  5% {
    width: 0;
  }
  15% {
    width:260px;
  }
  30% {
    width:260px;
  }
  75% {
    width:0px;
  }
  80% {
    width:0px;
  }
  85% {
    width:260px;
  }
  100% {
    width:260px;
  }
  
} */
.bannerInputBox {
  max-width: 527px;
}
.cta-demo {
  font-family: var(--poppins-semi-bold);
  padding: 2px 40px;
  transition: .5s;
  background: var(--secondry-color);
  border: 1px solid var(--secondry-color);
}
.cta-demo::before {
  background: var(--primary-color);
}
.cta-demo span:last-child {
  color: var(--white-color);
}
.cta-demo:hover::before {
  border-radius: 0;
  transform: translateY(0) scaleY(1);
  transition-delay: 0;
}
.cta-demo span:first-child {
  color: var(--secondry-color);
}


@media screen and (max-width:991.98px) {
  .topBanner {
    padding: 15px 0;
  }
  .bannertBox {
    flex-flow: column-reverse nowrap;
    gap: 20px;
  }
  .bannerImage {
    justify-content: center;
  }
  .bannerImage video {
    width: 500px;
  }
  .topBannerHeading {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 33px;
    letter-spacing: -2px;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    gap: 0 20px;
    align-items: center;
  }
  .topBannerHeading br {
    display: none;
  }
  .animateText {
    width: 390px;
    height: 56px;
    position: relative;
  }
  .Textchange {
    position: absolute;
    top: 6px;
    visibility: hidden;
    left: 15px;
  }
  .bannerContent p br {
    display: none;
  }
}
@media screen and (max-width:767.98px) {
  .bannerImage {
    max-width: 100%;
    width: 100%;
  }



  .topBannerHeading {
    font-size: 36px;
    line-height: 40px;
  }

  .topBannerHeading br {
    display: none;
  }

  .animateText {
    display: block;
    position: relative;
    top: auto;
    left: auto;
    height: 50px;
    width: 340px;
    margin: 5px 0;
  }

  .bannertBox {
    flex-direction: column-reverse;
    grid-gap: 30px;
  }

  .bannerImage1 {
    height: auto;
  }

  .topBanner2 {
    padding-top: 30px;
  }

  .topBannerHeading1 {
    font-size: 40px;
    line-height: 46px;
  }

  .topBannerHeading1 {
    margin-bottom: 20px;
  }

  .bannerInputBox {
    width: 100%;
  }

  .quickStart {
    flex-direction: column;
    justify-content: center;
    padding: 5px
  }

  .quickStart .btn {
    width: 100%;
    height: 46px;
    font-size: 16px;
  }

  .quickStart input {
    text-align: center;
  }
}
@media screen and (max-width:575.98px) {
  .bannerImage video {
    width: 100%;
  }
}
@media screen and (min-width:992px) {
  .topBanner {
    padding: 50px 0;
  }
  .topBannerHeading {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 33px;
    letter-spacing: -2px;
  }
  .animateText {
    width: 390px;
    height: 56px;
    left: 0;
    top: 47px;
    position: absolute;
  }
  .Textchange {
    position: absolute;
    top: 6px;
    visibility: hidden;
    left: 15px;
  }
  .bannerImage {
    margin-left: auto;
    max-width: 480px;
  }
  .bannerImage video {
    height: auto;
    width: 100%;
    object-fit: fill;
  }
  .bannerContent p br {
    display: none;
  }
}
@media screen and (min-width:1200px) {
  .topBannerHeading {
    font-size: 64px;
    line-height: 77px;
    margin-bottom: 33px;
    letter-spacing: -2px;
  }
  .bannerImage {
    max-width: 577px;
  }
  .animateText {
    width: 595px;
    height: 78px;
    left: -15px;
    top: 69px;
  }
  .bannerContent p br {
    display: flex;
  }
}