.topNotificationBar {
  background-color: var(--black-color);
  position: sticky;
  top: 76px;
  left: 0;
  width: 100%;
  z-index: 5;
}
.notificationBox {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.notificationMsg {
  color: var(--white-color);
  padding: 13px 30px;
  width: 100%;
  text-align: center;
  font-size: 13px;
  /* display: flex;
  flex-flow: row nowrap;
  align-items: center; */
  text-align: center;
}
.notificationMsg a {
  color: var(--primary-color);
  text-decoration: underline;
}
.notificationsCloseBtn {
  flex: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  background: none;
  box-shadow: none;
  outline: none;
  border: 0px;
  cursor: pointer;
}
.notificationItems {
  padding: 0 30px;
  border-left: 2px solid #fff;
}
.notificationItems:first-child {
  border-left: 0px;
  padding-left: 0px;
}

.notificationMsg {
  animation: marquee 25s linear infinite;
}
.notificationMsg:hover {
  animation-play-state: paused;
}
/* @keyframes marquee {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(100%);
  }
} */

@media screen and (max-width: 575.98px) {
  .notificationMsg {
    padding-left: 0px;
    padding-right: 10px;
  }
  .notificationMsg {
    padding: 10px 0;
  }
}
@media screen and (max-width: 991.98px) {
  .topNotificationBar {
    top: 49px;
  }
}
