.weAreTalkMain{background: var(--yellowBtn);position: relative;width: calc(100% - 220px);margin: auto;border-radius: 50px;padding: 50px 0;}
/* .weAreTalkMain::after{content: '';width: 362px;height: 363px;background: url('/public/assets/images/scaleten.svg') no-repeat;position: absolute;left: -13%;top: 28%;}
.weAreTalkMain::before{content: '';width: 362px;height: 363px;background: url('/public/assets/images/scaleten.svg') no-repeat;position: absolute;right: -13%;top: 28%;} */
.testimonialBox{margin: auto;display: flex;grid-gap: 50px;justify-content: center;}
.testContentBox{max-width: 362px;position: relative;border-radius: 40px;}
.testiContent{display: flex;flex-direction: column;}
.testiVieoBox{width: 362px;height: 269px;border-radius: 12px;overflow: hidden;position: relative;cursor: pointer;}
.testiVieoBox::after{content: '';width: 53px;height: 53px;background: url('/public/assets//images/playIcon.svg') no-repeat;display: block;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);}
.testContentBox .talkImg{position: absolute;width: 233px; right: 40px;bottom: 30px}
.testiFrom{margin-top: auto;}
.testHead{display: flex;grid-gap: 25px;align-items: flex-end;padding: 20px 0;justify-content: space-between;}
.userIcon{background: #E3E3E3;width: 83px;height: 83px;border-radius: 12px;display: flex;justify-content: center;align-items: center;;}
.playIcon{margin-left: auto;}