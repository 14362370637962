.footer {
    background: var(--191919);
    padding: 70px 0 20px
}

.footer1 {
    padding: 10px 0 20px
}

.footerContainer {
    display: grid;
}

.footerLogo {
    flex-shrink: 0
}
.adressBox {
    display: flex;
    gap: 20px;
    flex-flow: column nowrap;
    width: 100%;
}
.address {
    padding: 15px;
    border: solid 1px #BEBEBE;
    border-radius: 9px;
    display: flex;
    grid-gap: 20px;
}

.copyRight {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px #fff;
    margin: 20px auto 0;
    padding-top: 15px;
}

.copyRight1 {
    border-top: none;
}

.termsAndCondition {
    display: flex;
    grid-gap: 10px;
}
.termsAndCondition a {
    color: var(--color-666666);
}
.termsAndCondition a:hover {
    color: #fff;
}

.footerLink {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
}
.footerLink li{
    font-size: 14px;
    line-height: 20px;
}
.footerLink li a {
    color: var(--footerGray);
}
.footerLink li a:hover {
    color: #fff;
}
.footerContainer {
    display: flex;
    width: 100%;
    gap: 20px;
}
.footerContainer .footerLeft {
    display: flex;
    width: 100%;
    gap: 30px 20px;
    flex-flow: row wrap;
}
.footerContainer .footerRight {
    width: 300px;
    flex: none;
}
.footerLeft .footerLinks {
    flex: none;
    width: calc(calc(100% - 40px) / 3);
}
.footerLeft .footerApps {
    width: 100%;
    display: flex;
    gap: 60px;
    align-items: center;
}
.footerApps .isoBanner {
    width: 80px;
    flex: none;
}
.footerApps .isoBanner img {
    width: 80px;
}
.footerApps .appLinks {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
    width: 100%;
}
@media screen and (max-width:991.98px) {
    .footerContainer {
        flex-flow: row wrap;
    }
    .adressBox {
        flex-flow: row nowrap;
    }
    .adressBox .address {
        width: calc(calc(100% - 20px) / 2);
        flex: none;
    }
    .footerContainer .footerRight {
        width: 100%;
        margin-top: 20px;
    }
}
@media screen and (max-width:767.98px) {
    .footer {
        padding: 30px 0 10px
    }


    .copyRight1 {
        flex-direction: column;
        justify-content: center;
        grid-gap: 10px;
    }

    .copyRight1 p {
        text-align: center;
    }

    .footer {
        padding: 20px 0;
    }

    .termsAndCondition {
        justify-content: center;
    }
}


@media screen and (max-width:575.98px) { 
    .footerContainer .footerLeft {
        flex-flow: row wrap;
    }
    .adressBox {
        width: 100%;
        flex: none;
        flex-flow: column nowrap;
    }
    .adressBox .address {
        width: 100%;
    }
    .footerLeft .footerApps {
        flex-flow: column nowrap;
        align-items: flex-start;
    }
    .footerLeft .footerApps {
        gap: 20px;
    }
    .footerLeft .appLinks {
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}