.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 30;
}

.modalBody {
    position:fixed;
    background: transparent;
    width: auto;
    height: auto;
}
.closeModal {
    position: absolute;
    border: none;
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    cursor: pointer;
}
.demoModalBox {
    background-image: linear-gradient(to bottom, #f5d65f, #fdfcf4);
}
.demoModalThumb {
    flex: none;
    width: 360px;
    margin-left: -110px;
    margin-top: -20px;
}
.demoModalThumb img {
    width: 100%;
}
.demoModalCont {
    width: 100%;

}
.demoUserForm {
    margin-top: 30px;
}
.demoModalHeading {
    margin: 20px 0;
}
.demoModalHeading h3 {
    color: var(--191919);
    font-family: 'poppinsbold';
}
.demoModalHeading h3 span{
    margin-bottom: 10px;
    padding: 0 10px;
    line-height: 54px;
}
.demoForm {
    display: block;
}
.formControl {
    margin-bottom: 12px;
}
.formInput {
    padding: 6px 15px;
    border-radius: 10px;
    border: solid 1px #000;
    background-color: #fff;
    width: 100%;
    color: #000;
    font-family: 'poppinsregular';
    font-size: 14px;
    height: 42px;
}
.formInput::placeholder {
    color: #000;
}
.inputError {
    border: solid 1px rgb(211, 16, 16);
}
.errorMessage {
    background: red;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-top: -10px;
}


.demoThanks {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
}
.thanksIcon {
    margin-top: 20px;
}

.HeadingImp {
    position: relative;
    width: max-content;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.HeadingImp::after {
    content: '';
    background: url(/public/assets/images/svgArt/thank-strip.svg) no-repeat;
    width: 100%;
    height: 16px;
    margin-left: auto;
    position: absolute;
    bottom: -9px;
    right: 0;
}
.demoThanks .demoModalHeading h3 span::after {
    width: 100%;
}
.demoModalHeading p {
    font-family: 'poppinsregular';
    font-size: 14px;
    color: #6c6c6c;
    line-height: 18px;
    margin-top: 10px;
}
.redirectBtn {
    width: 100%;
}

@media screen and (max-width:767.98px) {
    .modal {
        width: 100%;
    }
    .demoModalThumb {
        display: none;
    }
    .modalBody {
        left: 0px;
        bottom: 0px;
    }
    .demoModalBox {
        border-radius: 15px 15px 0 0;
        max-width: 400px;
        width: 100%;
        padding: 20px;
    }
    .demoModalHeading h3 {
        line-height: 30px;
        font-size: 30px;
    }
    .demoModalHeading h3 span {
        padding-left: 0px;
    }
}
@media screen and (min-width:768px) {
    .modalBody {
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
    }
    .demoModalBox {
        border-radius: 15px;
        width: 90%;
        padding: 30px 40px;
        display: flex;
        gap: 40px;
        flex-flow: row nowrap;
    }
    .demoModalHeading h3 {
        line-height: 46px;
        font-size: 46px;
    }
    .demoModalBox {
        width: 700px;
    }
}
@media screen and (min-width:992px) {
    .demoModalBox {
        width: 800px;
    }
    .demoModalHeading h3 {
        line-height: 46px;
        font-size: 46px;
    }
}