.EssentialBuilding{background: var(--191919);}
.socialIcon{display: flex;margin-left: auto;grid-gap: 15px;}
.socialIcon li a {
    line-height: 0px;
    opacity: 0.4;transition:.5s;cursor: pointer; border: 1px solid #fff; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; border-radius: 5px; overflow: hidden;
}
.socialIcon li a:hover {
    opacity: 1;
}
.socialIcon img {
    width: 18px;
}
.logoEssential{padding-right: 25px;border-right:solid 1px #FFFFFF;margin-right: 25px;}
.logoEssential {width: 230px;}
.logoEssential a{display: block;line-height: 0px;}
.logoEssential svg{width: 100%;height: 32px;}
.containerFix{border:solid 1px #FFFFFF;border-radius: 12px;height: 120px;align-items: center;width: 100%;display: flex;padding: 40px;}


@media screen and (max-width:767.98px) {
    .EssentialBuilding {
        padding: 20px 0;
    }
    .logoEssential {
        height: 50px;
    }
    .containerFix{flex-direction: column;height: auto;padding: 20px;grid-gap: 20px;}
    .containerFix p{text-align: center;}
    .logoEssential{border-right: 0px;margin-right: initial;padding-right: 0px;    height: 35px;}
    .socialIcon{margin-left: initial;}
}