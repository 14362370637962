.hm-features {
    overflow: hidden;
    background-color: var(--secondry-color);
}
.featuresGrid {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    gap: 50px 0;
}
.featuresGrid .featuresCol {
    width: 100%;
    background-color: rgb(255 255 255 / 10%);
    border-radius: 20px;
    padding-bottom: 0px;
    overflow: hidden;
}
.featuresBox {
    width: 100%;
    display: flex;
}
.featuresBox .featuresContent {
    width: 100%;
    padding-left: 40px;
}
.featuresBox .featuresContent .featuresHeading {
    display: flex;
    align-items: center;
    gap: 15px;
}
.featuresBox .featuresThumb {
    flex: none;
}
.featuresBox .featuresCount span {
    align-items: center;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 28px;
    height: 60px;
    justify-content: center;
    padding-top: 4px;
    width: 60px;
    background: var(--primary-color);
    color: var(--secondry-color);
    font-family: var(--poppins-bold);
}
.featuresBox h3 {
    color: var(--white-color);
    font-size: 50px;
    font-family: var(--poppins-semi-bold);
    line-height: 60px;
    margin-top: 15px;
}
.featuresBox .featureLogo {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    line-height: 0px;
    padding: 10px;
    width: auto;
    display: flex;
}
.featuresBox .featureLogo img {  
    width: 180px;
    object-fit: cover;
    object-position: center;
    height: auto;
}
.featuresBox p {
    color: var(--white-color);
    font-size: 16px;
    font-family: var(--poppins-regular);
    line-height: 24px;
    margin-top: 15px;
}
.featuresBox ul {
    display: flex;
    gap: 6px 0;
    flex-flow: column nowrap;
    margin: 20px 0 30px 0;
}
.featuresBox ul li {
    color: var(--white-color);
    font-size: 16px;
    font-family: var(--poppins-regular);
    line-height: 24px;
    position: relative;
    padding-left: 20px;
}
.featuresBox ul li::before {
    content: '';
    width: 8px;
    height: 8px;
    display: block;
    background-color: var(--white-color);
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
}
.featuresBox a {
    display: inline-block;
}
.featuresBox .featuresThumb .thumbBox {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    position: relative;
    padding: 40px 20px;
}

.featuresBox .featuresThumb .thumbBox img {
    position: relative;
    z-index: 3;max-height: 380px;
}

.featuresGrid .featuresCol.featureBg1 {
    background-color: rgb(245 214 95 / 10%);
}
.featuresGrid .featuresCol.featureBg1 .featuresThumb .thumbBox::after {   
    background-color: #26221a;
}

@media screen and (max-width:767.98px) {
    .hm-features {
        border-radius: 20px;
        padding: 20px 0;
    }
    .featuresBox {
        flex-flow: column-reverse nowrap;
        gap: 20px;
    }
    .featuresGrid {
        gap: 20px;
    }
    .featuresBox .featuresThumb {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .featuresBox .featuresThumb .thumbBox {
        width: 300px;
    }
    .featuresBox .featuresContent {
        padding-bottom: 30px;
        padding-left: 15px;
    }
    
    .featuresBox .featuresThumb {
        padding-top: 20px;
    }
    .featuresBox .featuresCount span {
        width: 42px;
        height: 42px;
        font-size: 20px;
    }
    .featuresBox .featureLogo img {
        width: 120px;
    }
}
@media screen and (min-width:768px) {
    .hm-features {
        border-radius: 40px;
        padding: 50px 0;
    }
    .featuresBox {
        flex-flow: row nowrap;
        gap: 30px;
        align-items: flex-start;
        align-items: center;
        padding: 20PX 0;
    }
    .featuresBox .featuresContent {
        width: 100%;
    }
    .featuresBox .featuresThumb {
        width: 300px;
        flex: none;
        padding-top: 40px;
    }
    .featuresBox .featuresThumb .thumbBox::before,
    .featuresBox .featuresThumb .thumbBox::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .featuresBox .featuresThumb .thumbBox::before {
        background-color: var(--primary-color);
        top: -11px;
        left: -11px;
        z-index: 1;
        border-radius: 28px;
    }
    .featuresBox .featuresThumb .thumbBox::after {
        border-radius: 20px;
    }
    .featuresBox .featuresThumb .thumbBox::after {   
        background-color: #27262a;
        z-index: 2;
        border-radius: 20px 0 0 20px;
    }
}
@media screen and (min-width:992px) {
    .hm-features {
        border-radius: 40px;
        padding: 70px 0;
    }
    .featuresBox {
        gap: 50px;
        padding: 0px;
    }
    .featuresBox .featuresThumb {
        width: 370px;
    }
}
@media screen and (min-width:1200px) {
    .hm-features {
        border-radius: 50px;
        padding: 100px 0;
    }
    .featuresBox .featuresThumb {
        width: 480px;
    }
}