/*feture box*/
.fetureBox{max-width: 1070px;width: 100%;background: var(--191919);padding: 20px; margin: auto;display: flex;justify-content: space-between;align-items: center;margin-bottom: 120px}
.fetureBox:last-child{margin-bottom: 0}
.fetureBoxMain{position: relative;}
.fetureBoxMain .fetnew{width: 100%;}
.fetureWraper{width: 100%;}
.countNumber{background: #747474;width: 40px;height: 40px;color: #FFFFFF;display: flex;justify-content: center;align-items: center;border-radius: 50%;font-size: 19px;margin-bottom: 15px;padding-top: 4px;}
.realTime{position: absolute;left: -40px;}
.fetnew figure, .panel figure{position: relative;}
.demonstrateProducts{position: absolute;left: -86px;top: -20px;}
.dynamicProduct{position: absolute; left: -16px;top: -34px;}
.interactiveShopping{position: absolute; left: -75px;bottom: -2px;}


.orange {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .text {
    color: #fff;
  }
  
  .black {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #070707;
  }

  
  
  .textWrap {
    position: relative;
    overflow: hidden;
    width: 450px;
    height: 80vh;
  }
  
  .panelText {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1;
    width: 100%;
    height: 100%;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 900;
    transform:translateY(100%);
    opacity: 0;
  }
  
  
  
  
  
  
  .pWrap {
    position: relative;
    /* overflow: hidden; */
    width: 50%;
    height: 80vh;
  }
  
  .panel {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    opacity: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

@media screen and (max-width: 767.98px){
  .fetureBox {
    flex-flow: column-reverse nowrap;
  }
  .fetureBox figure img {
    height: 180px;
  }

  .fetureContentEl {
    width: 100%;
  }
  .fetureContentEl h3 {
    font-size: 30px;
    line-height: 40px;
  }  
  .fetureContentEl h3 br {
    display: none;
  }
  .listEl {
    margin-bottom: 20px;
  }
}